<template>
    <div class="archive--view">
        <img class="back__image" src="../assets/icons/back.svg" @click="backF">
        <!--    <video id="player"></video>-->
        <iframe :src="src" class="archive__video"></iframe>
    </div>
</template>

<script>
    // import FlussonicMsePlayer from '@flussonic/flussonic-mse-player'

    export default {
        name: 'Archive',
        data () {
            return {
                src: ''
            }
        },
        methods: {
            backF () {
                this.$router.push({ name: 'Home' })
            }
        },
        mounted () {
            this.$store.commit('changeShowHeader', false)
            const camera = JSON.parse(localStorage.getItem('camera'))
            console.log(camera.archiveUri)
            const url = new URL(camera.archiveUri)
            console.log(url)
            const streamName = url.pathname.substr(1, url.pathname.indexOf('/index.m3u8') - 1)

            const wsUrl = 'https://newmedia2.eyeinc.ru:444'
            const search = `embed.html${url.search}&dvr=true`
            this.src = `${wsUrl}/${streamName}/${search}`

            // const wsUrl = 'wss://newmedia2.eyeinc.ru:444'
            // const search = `mse_ld${url.search}&dvr=true`
            // const newUrl = `${wsUrl}/${streamName}/${search}`
            //
            // var element = document.getElementById('player')
            // window.player = new FlussonicMsePlayer(element, newUrl)
            // window.player.play()
            const el = document.getElementById('app')

            el.classList.add('app_archive')
        },
        beforeDestroy() {
          document.getElementById('app').classList.remove('app_archive')
        }
    }
</script>

<style scoped lang="scss">
    .archive--view{
        width: 100%;
        height: 100%;
        background-color: black;
        overflow: hidden;

        video{
            width: 100%;
            height: 100%;
        }
    }

    .back__image{
        position: absolute;
        width: 20px;
        height: 20px;
        left: 20px;
        top: 25px;
        cursor: pointer;
        z-index: 1;
    }

    .archive__video{
        width: 100%;
        height: 100%;
        border: 0;
        outline: 0;
    }
</style>
